
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.show-more {
    word-break: break-word;

    ::v-deep {
        .el-dialog {
            margin: 15vh auto;

            &__header {
                padding: 20px 20px 10px;
            }

            &__body {
                padding: 30px 20px;
            }

            &__footer {
                padding: 10px 20px 20px;
            }

            .el-button {
                padding: 12px 20px;
            }
        }
    }

    &-link {
        cursor: pointer;
    }
}
