
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.timeline {
    $timeline-badge-size: 30px;

    &-item {
        display: flex;
    }

    &-badge {
        flex-shrink: 0;
        box-sizing: border-box;
        border-radius: 50%;
        width: $timeline-badge-size;
        height: $timeline-badge-size;
        z-index: 1;
    }

    &-panel {
      flex-grow: 1;
    }
}
