
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.media {
    &-content {
        display: flex;
        font-size: 1em;
    }
}

.is-light {
    .media {
        &-content {
            color: black !important;
        }
    }
}

.dropdown {
    &-wrapper {
        cursor: pointer;
        padding: 0 1em;
        text-align: center;
        white-space: nowrap;

        .media {
            align-items: center;
        }
    }

    &-icon {
        @apply inline-flex items-center justify-center w-4 h-4;
    }
}
