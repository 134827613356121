
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";

          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        

.timeline {
    $timeline-badge-size: 30px;

    &-list-item {
        position: relative;

        & ~ &:before,
        &:not(:last-child):after {
            content: "";
            position: absolute;
            width: 3px;
            background-color: #e0e0e0;
            transform: translateX(-50%);
            left: $timeline-badge-size / 2;
        }

        & ~ &:before {
            height: 0.75em;
        }

        &:not(:last-child):after {
            top: calc(0.75em + #{ $timeline-badge-size });
            height: calc(100% - #{ $timeline-badge-size });
        }
    }
}
